import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isNumber, isString } from 'lodash-es';
import { map } from 'rxjs/operators';

import { HttpApiPersistenceService, Query, StateService } from '@locumsnest/core/src';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { ISectorEntity } from '../../interfaces/api/sector-entity';
import { UpsertMultipleMessage, UpsertOneMessage } from './sector.messages';
import { selectAllSectors, selectSector } from './sector.selectors';

@Injectable({
  providedIn: 'root',
})
export class SectorPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  ISectorEntity
> {
  protected readonly endpoint = 'sector';
}

@Injectable({
  providedIn: 'root',
})
export class SectorService extends StateService<ISectorEntity> {
  constructor(private store: Store, protected persistenceService: SectorPersistenceService) {
    super();
  }

  getAll() {
    return this.store.pipe(select(selectAllSectors), filterNonEmpty);
  }

  getOne(id: number) {
    return this.store.pipe(select(selectSector(id)));
  }

  fetch(query?: Query) {
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(map((sector) => new UpsertOneMessage({ sector })));
    return this.persistenceService
      .retrieve(query)
      .pipe(map(({ results }) => new UpsertMultipleMessage({ sectors: results })));
  }
}
