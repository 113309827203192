import { ISectorEntityState, ISectorFeatureState, ISectorSharedState } from './interfaces';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { selectAll } from './sector.reducer';
import { ISectorEntity } from '../../interfaces/api/sector-entity';

export const getEntityState = (state: { entityState: ISectorEntityState }) => state.entityState;

export const getSector = (sectorId: number) => (sectors: ISectorEntity[]) =>
  sectors.find((sector) => sector.id === sectorId);
export const selectSectorState =
  createFeatureSelector<ISectorFeatureState & ISectorSharedState>('sectors');

export const selectSectorEntityState = createSelector(selectSectorState, getEntityState);

export const selectAllSectors = createSelector(selectSectorEntityState, selectAll);

export const selectSector = (sectorId) => createSelector(selectAllSectors, getSector(sectorId));
